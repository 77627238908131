import React from 'react';
import './Seguridad.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';

function Seguridad() {
  return (
    <>
    <Sidebar/>
    <div className="seguridad">
        <h2>Seguridad</h2>
        <div className="seguridad__container">
            <h3>No hay datos</h3>
        </div>
    </div>

    </>
  )
}

export default Seguridad