import React from 'react';
import './ListasBar.scss';
import { Link } from 'react-router-dom';

function OptionBar() {
    return (
        <div>
            <nav className='options__nav'>
                <Link to='/listas-de-chequeo'><p>Higiene de manos</p></Link>
                <Link to='/actividades-actualizacion'><p>Asepsia y antiasepsia</p></Link>

            </nav>
        </div>
    )
}

export default OptionBar
