import React from 'react';
import './Listas.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import GeneralFilter from '../../components/GeneralFilter/GeneralFilter';
import ListasBar from '../../components/ListasBar/ListasBar';
import InfoCard from '../../components/InfoCard/InfoCard';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';


function Listas() { 
    
    const data = [
        {
            key: '1',
            title: 'No hay datos',
            subtitle: 'Adherencia total'
        },
        {
            key: '2',
            title: 'No hay datos',
            subtitle: 'Servicio con mayor adherencia'
        },
        {
            key: '3',
            title: 'No hay datos',
            subtitle: 'Cargo con mayor adherencia'
        },
        {
            key: '4',
            title: 'No hay datos',
            subtitle: 'Servicio con mejor dotación'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='listas'>
            <h2>Estrategia multimodal</h2>
             <ListasBar/>
            <GeneralFilter/>
            <h2>Higiene de manos</h2>
            <h4>Últimas 24 horas</h4>
            <div className="listas__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="listas__graph-container">
                <div className='listas__graph-title'>
                <h5>Porcentaje de adherencia por servicio</h5>
                <div className="listas__graph">
                <Line
                        data={{
                            labels:[],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="listas__graph-title">
                <h5>Porcentaje de adherencia por cargo</h5>
                    <div className="listas__graph">
                    <Pie
                        data={{
                            labels:[],
                            datasets: [{
                                label: '',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="listas__card-container">
                <div className='listas__card-title'>
                <h5>Porcentaje de adherencia por momento</h5>
                <div className="listas__card">
                <Bar
                            data={{
                                labels:[],
                                datasets: [{
                                    label: 'Antibióticos usados en el primer semestre',
                                    data: [],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='listas__card-title'>
                    <h5>Adherencia del mejor servicio por cargo</h5>
                <div className="listas__card">
                <Doughnut
                         data={{
                            labels:[],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='listas__card-title'>
                    <h5>Adherencia del mejor cargo por servicio</h5>
                <div className="listas__card">
                <Pie
                         data={{
                            labels:[],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default Listas
