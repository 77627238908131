import React from 'react';
import './Soporte.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BiPhone, BiChat, BiUserPlus, BiUser } from "react-icons/bi";
import InfoCard from '../../components/InfoCard/InfoCard';

function Soporte() {

    const data = [
        {
            key: '1',
            title: <BiPhone/>,
            subtitle: 'Llamada',
        },
        {
            key: '2',
            title: <BiChat/>,
            subtitle: 'Whatsapp'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Soporte<span>.</span></h2>
            <div className="soporte__container">
                <Link to="/perfil">
                    <InfoCard
                        title={<BiUser/>}
                        subtitle='Perfil'
                    /> 
                </Link>
                <Link to="/crear-usuario">
                    <InfoCard
                        title={<BiUserPlus/>}
                        subtitle='Crear usuario'
                    /> 
                </Link>
                <a href="https://meetings.hubspot.com/sebastian342">
                    <InfoCard
                        title={<BiPhone/>}
                        subtitle='Agendar llamada'
                    /> 
                </a>
                <a href="https://wa.me/573004523259?text=Solicito%20soporte%20de%20la%20plataforma%20Vieci">
                    <InfoCard
                        title={<BiChat/>}
                        subtitle='Whatsapp'
                    />    
                </a>
            </div>
        </div>
        </>
    )
}

export default Soporte
