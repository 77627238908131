import React, { useState } from 'react';
import './Analisis.scss';
import { Link } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Sidebar from '../../components/Sidebar/Sidebar';
import Docbar from '../../components/Docbar/Docbar';
import Button from '../../components/Button/Button';

function Analisis() {

    const data = [
        {
            id: '1',
            period: '1 ago - 18 ago ',
            month: 'Agosto',
            date: '18/08/2022',
            report: '19/08/2022',
            name: 'Análisis de historías clínicas',
            link: <a href="https://docs.google.com/spreadsheets/d/12ZQz1UDa66SzfNXsf5jUtpHgDsZtfT3e/edit#gid=332920687" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '2',
            period: '19 ago - 31 ago ',
            month: 'Agosto',
            date: '13/09/2022',
            report: '14/09/2022',
            name: 'Análisis de historías clínicas',
            link: <a href="https://docs.google.com/spreadsheets/d/1X0DM1CPHv8j-DHldkr0-hU-TUb1cgj_i/edit#gid=332920687" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
    ]

    const [search, setSearch] = useState('');

    const filterData = data.filter((data)=> {
       return data.name.toLowerCase().includes(search.toLowerCase())
   })

    const handleSearch = (e) => {
       setSearch(e.target.value)
    }
     
    return (
        <>
        <Sidebar/>
        <div className='analisis'>
            <h2>Análisis de IAAS<span>.</span></h2>
            <div className="analisis__container">
                {/* <div className="analisis__search">
                    <Input
                        type='text'
                        placeholder={'Buscar formato'}
                        value={search}
                        onChange={handleSearch}
                    />
                </div> */}
                <table>
                        <tr>
                            <th><b>Nombre de archivo</b></th>
                            <th><b>Periodo</b></th>
                            <th><b>Mes</b></th>
                            <th><b>Fecha de actualización</b></th>
                            <th><b>Fecha de reporte</b></th>
                            <th><b>Acciones</b></th>
                           
                        </tr>
                        {
                                    filterData.map( item => 
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.period}</td>
                                            <td>{item.month}</td>
                                            <td>{item.date}</td>
                                            <td>{item.report}</td>
                                            <td className='tb__container'>{item.link}</td>
                                        </tr>
                                        )
                                }
                    </table>
            </div>
        </div>
        </>
    )
}

export default Analisis
