import React, { useState } from 'react';
import './Documents.scss';
import { Link } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Sidebar from '../../components/Sidebar/Sidebar';
import Docbar from '../../components/Docbar/Docbar';
import Button from '../../components/Button/Button';

function Documents() {

    const data = [
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de PQRS',
            code: '1',
            link: <a href="https://docs.google.com/document/d/172lnpd1PtArLi43-CmNXCK9Vrw4pCzuR/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de ficha técnica de reuso',
            code: '1',
            link: <a href="https://docs.google.com/document/d/1kS9O63i1RcQqqLEGg-Uvnm9H2KDuuuE7/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de seguimiento de PQRs',
            code: '3.1',
            link: <a href="https://docs.google.com/document/d/1gg-YMsJxsC-FA4D1YR1gOgv7IaCZQoc_/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de reporte de eventos adversos',
            code: '3.2',
            link: <a href="https://docs.google.com/document/d/1vfmJ_GqB9s4QhQl6I9NgZS4BCogjiBX-/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato plantilla segumiento eventos adversos',
            code: '2',
            link: <a href="https://docs.google.com/spreadsheets/d/1Wox0sDD77sr0EQ86AFECM_ObDGr-BpwZ/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato ahorro cierre mes',
            code: '3',
            link: <a href="https://docs.google.com/spreadsheets/d/1pfZW-7_5N7rZjwMwozGVxjsWo4l-A4A9/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato plantilla segumiento eventos adversos',
            code: '2',
            link: <a href="https://docs.google.com/spreadsheets/d/1Wox0sDD77sr0EQ86AFECM_ObDGr-BpwZ/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de inducción',
            code: '5',
            link: <a href="https://docs.google.com/spreadsheets/d/1c4EqNn8d2TL4XhH6CQMDtpHvS0iVHse6/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Registro de limpieza y desinfección de áreas y equipos',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1SfAn9mUdgFxCCBR1ZF4uag28aeSLp5FS/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Registro de limpieza y desinfección de neveras, estantería, muebles, recipientes plásticos.',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/16yP0-NjGUQUFmw-0qWt-GZu6e-Q-7tjn/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de ronda de seguridad ambiebtal',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1u8EYwiJKYSGgP2Whldr3E-DpvOdDends/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de listado de dispositivos médicos',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1GgfMYnoDGVnfyPzxPG5iT9q_8Zpk4AAL/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de medicamentos de alto riesgo',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1yRMJo9F2CN3zWY1lYl7BkdMpMCz3LOb7/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato rececpión técnica de gases medicinales',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1oSp30shmdb84Bw_RHVDOhfnOFw-SFt9f/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato rececpión técnica de medicamentos y dispositivos médicos',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1oSp30shmdb84Bw_RHVDOhfnOFw-SFt9f/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de pedido sugerido mensual',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1uxj7-zBiMXBCPrGNXmG1NDPThyADi-UR/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de pedido sugerido mensual',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1uxj7-zBiMXBCPrGNXmG1NDPThyADi-UR/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Reporte de comsumo cierre de mes',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/15jHDjAQ9g5xsgu5Q1V-6a_vciPDfFc3l/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de elementos de protección personal',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1G56Vi-VB9z-BK3XZDxkFirB8SkcuF_N9/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de elementos de protección personal',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1G56Vi-VB9z-BK3XZDxkFirB8SkcuF_N9/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de recepción técnica de medicamentos',
            code: '6',
            link: <a href="https://docs.google.com/document/d/1eNmKAQk1bxA6rocrlklkS5EUU9zYlohg/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de audotoria interna de fórmulas médicas ',
            code: '6',
            link: <a href="https://docs.google.com/document/d/1_MfuNOWu2Lv2qHQ8ME_EU9nWDihzI5gV/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de entrega de medicamentos ambulatorios',
            code: '6',
            link: <a href="https://docs.google.com/document/d/1Oa9x4X6qwCmlyAF13CKaWAiZOzM93M3O/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de ajuste de inventario',
            code: '6',
            link: <a href="https://docs.google.com/document/d/1kVin_BwX8c4nWnauCMno_7TctrkbJOHV/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Base de información medicamentos ambulatorios',
            code: '6',
            link: <a href="https://docs.google.com/document/d/1kVin_BwX8c4nWnauCMno_7TctrkbJOHV/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de acta de recepción de dispositivos',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1WDLYlySbQR3YgXS2jptwQoNi0msoxY7q/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de rotulación',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1yymJFosA1iO-gQ-_4-2lcv7QMPGeStbV/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Formato',
            name: 'Formato de préstamo de HC',
            code: '6',
            link: <a href="https://docs.google.com/spreadsheets/d/1vVXfSeWKIA4k3Oey9KT0PocqEmYvV17n/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=trueFIGMA" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },

    ]

    const [search, setSearch] = useState('');

    const filterData = data.filter((data)=> {
       return data.name.toLowerCase().includes(search.toLowerCase())
   })

    const handleSearch = (e) => {
       setSearch(e.target.value)
    }
     
    return (
        <>
        <Sidebar/>
        <div className='documentos'>
            <h2>Documentos<span>.</span></h2>
            <Docbar /> 
            <div className="documentos__container">
                <h3>Formatos</h3>
                <div className="documentos__search">
                    <Input
                        type='text'
                        placeholder={'Buscar formato'}
                        value={search}
                        onChange={handleSearch}
                    />
                </div>
                <table>
                        <tr>
                            <th><b>Tipo de Documento</b></th>
                            <th><b>Nombre del documento</b></th>
                            <th><b>Código</b></th>
                            <th><b>Acciones</b></th>
                           
                        </tr>
                        {
                                    filterData.map( item => 
                                        <tr>
                                            <td>{item.type}</td>
                                            <td>{item.name}</td>
                                            <td>{item.code}</td>
                                            <td className='tb__container'>{item.link}</td>
                                        </tr>
                                        )
                                }
                    </table>
            </div>
        </div>
        </>
    )
}

export default Documents
