import React from 'react';
import './Checklists.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BiPhone, BiChat, BiCheckCircle } from "react-icons/bi";
import InfoCard from '../../components/InfoCard/InfoCard';

function Soporte() {

    const data = [
        {
            key: '1',
            title: <BiPhone/>,
            subtitle: 'Llamada',
        },
        {
            key: '2',
            title: <BiChat/>,
            subtitle: 'Whatsapp'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Vieci Checklists<span>.</span></h2>
            <div className="soporte__container">
                <Link to='/dashboard'>
                    <InfoCard
                        title={<BiCheckCircle/>}
                        subtitle='Higiene de manos'
                    /> 
                </Link>
            </div>
        </div>
        </>
    )
}

export default Soporte
