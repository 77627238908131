import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDoQmHDNLz48hWWi69T-h1dI5fRddDa42w",
  authDomain: "mf-main.firebaseapp.com",
  projectId: "mf-main",
  storageBucket: "mf-main.appspot.com",
  messagingSenderId: "414502235642",
  appId: "1:414502235642:web:db2084fadc59ea2e744843",
  measurementId: "G-BN06R5XPVT"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const storage = getStorage(app)

