import React from 'react';
import './Formatos.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import { MdDeleteOutline, MdStorefront,MdOutlineReport,  MdOutlineCleaningServices} from "react-icons/md";
function Formatos() {

    const data = [
        {
            id: '',
            title: <MdStorefront size={30}/>,
            subtitle: 'Administración',
            path: '/listas-de-chequeo'
        },
        {
            id: '',
            title: <MdOutlineCleaningServices size={30}/>,
            subtitle: 'Limpieza y ambiente',
            path: '/listas-de-chequeo'
        },
        {
            id: '',
            title: <MdOutlineReport size={30}/>,
            subtitle: 'Eventos adversos',
            path: '/listas-de-chequeo'
        },
        {
            id: '',
            title: <MdDeleteOutline size={30}/>,
            subtitle: 'Manejo de residuos',
            path: '/listas-de-chequeo'
        },
    ]
  return (
    <>
    <Sidebar/>
    <div className="formatos">
        <h2>Formatos</h2>
        <div className="formatos__container">
            {
                   data.map( item => 
                    <Link to={item.path}>
                        <InfoCard
                        title={item.title}
                        subtitle={item.subtitle}                    />
              
                    </Link>
                ) 
            }
        </div>
    </div>
    </>
  )
}

export default Formatos;