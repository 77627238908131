import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserAuthContextProvider } from '../providers/UserAuthContext';
import ProtectedRoute from '../providers/ProtectedRoute';
//components
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import Notification from '../pages/Notification/Notification';
import Calendario from '../pages/Calendario/Calendario';
import Evento from '../pages/Calendario/Evento/Evento';
import Informes from '../pages/Documents/Informes/Informes';
import Documents from '../pages/Documents/Documents';
import Checklists from '../pages/Checklists/Checklists';
import Actas from '../pages/Documents/Actas/Actas';
import Protocolos from '../pages/Documents/Protocolos/Protocolos';

//Upload
import SubirArchivos from '../pages/SubirArchivos/SubirArchivos';
import Analisis from '../pages/Analisis/Analisis';

//departamentos
import Formatos from '../pages/Formatos/Formatos';
//analisis
import Listas from '../pages/Listas/Listas.jsx';
  import HigieneManos from '../pages/Listas/HigieneManos/HigieneManos';
  import Seguridad from '../pages/Listas/Seguridad/Seguridad';
//Admin
import Perfil from '../pages/Perfil/Perfil.jsx';
import CrearUsuario from '../pages/CrearUsuario/CrearUsuario';
import Soporte from '../pages/Soporte/Soporte';



function App() {
  return (
  <UserAuthContextProvider>
    <Router>
      <Routes>
        <Route exact path='/' element={<Login/>}/>
        <Route exact path='/dashboard' element={ <ProtectedRoute><Dashboard/></ProtectedRoute>}/>
        {/* Vision general */}
        <Route exact path='/notificaciones' element={<ProtectedRoute><Notification/></ProtectedRoute>}/>
        <Route exact path='/calendario' element={<ProtectedRoute><Calendario/></ProtectedRoute>}/>
        <Route exact path='/vieci-checklists' element={<ProtectedRoute><Checklists/></ProtectedRoute>}/>
        <Route exact path='/evento' element={<ProtectedRoute><Evento/></ProtectedRoute>}/>
        <Route exact path='/informes' element={<ProtectedRoute><Informes/></ProtectedRoute>}/>
        <Route exact path='/documentos-reportes' element={<ProtectedRoute><Documents/></ProtectedRoute>}/>
        <Route exact path='/documentos-protocolos' element={<ProtectedRoute><Protocolos/></ProtectedRoute>}/>
        <Route exact path='/documentos-informes' element={ <ProtectedRoute><Informes/></ProtectedRoute>}/>
        {/* Upload */}
        <Route exact path='/upload' element={<ProtectedRoute><SubirArchivos/></ProtectedRoute>}/>
        {/* Analisis */}
        <Route exact path='/formatos' element={<ProtectedRoute><Formatos/></ProtectedRoute>}/>
   
        <Route exact path='/upload' element={<ProtectedRoute><SubirArchivos/></ProtectedRoute>}/>
        <Route exact path='/analisis' element={<ProtectedRoute><Analisis/></ProtectedRoute>}/> 
            {/* Perfil */}
        <Route exact path='/listas-de-chequeo' element={<ProtectedRoute><Listas/></ProtectedRoute>}/>
          <Route exact path='/higiene-manos' element={<ProtectedRoute><HigieneManos/></ProtectedRoute>}/>
          <Route exact path='/seguridad' element={<ProtectedRoute><Seguridad/></ProtectedRoute>}/>
        <Route exact path='/perfil' element={<ProtectedRoute><Perfil/></ProtectedRoute> }/>
        <Route exact path='/crear-usuario' element={<ProtectedRoute><CrearUsuario/></ProtectedRoute>}/>
        <Route exact path='/centro-de-ayuda' element={<ProtectedRoute><Soporte/></ProtectedRoute>}/>
      </Routes>
    </Router>
  </UserAuthContextProvider>
  );
}

export default App;
