import React from 'react';
import './HigieneManos.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';
function HigieneManos() {
  return (
    <>
    <Sidebar/>
    <div className="higiene">
        <h2>Higiene de manos</h2>
        <div className="higiene__container">
          <h3>No hay datos</h3>
        </div>
    </div>
    </>
  )
}

export default HigieneManos