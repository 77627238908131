import React from 'react';
import './Docbar.scss';
import { Link } from 'react-router-dom';

function Docbar() {
    return (
        <div>
            <nav className='documentos__nav'>
                <Link to='/documentos-informes'><p>Consultas</p></Link>
                <Link to='/documentos-reportes'><p>Formatos</p></Link>
                <Link to='/documentos-protocolos'><p>Protocolos</p></Link>
                {/* { <Link to='/documentos-actas'><p>Actas</p></Link>}  */}
            </nav>
        </div>
    )
}

export default Docbar
