import React, { useState } from 'react';
import './Protocolos.scss';
import { Link } from 'react-router-dom';
import Input from '../../../components/Input/Input';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Button from '../../../components/Button/Button';
import Docbar from '../../../components/Docbar/Docbar';
import InfoCard from '../../../components/InfoCard/InfoCard';


function Protocolos() {
    const data = [
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo lavado de manos',
            code: '',
            link: <a href="https://docs.google.com/document/d/11vsFpLDtP8EKH6OZKI1MJ_ghBXHFOQmY/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de devolución de medicamentos y dispositivos medicos',
            code: '',
            link: <a href="https://docs.google.com/document/d/1bCqiDPNOE7PTj3ttZYZsrpb9hk3TMUvG/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Procedimiento',
            name: 'Procedimiento de manejo de derrames de riesgo biológico',
            code: '',
            link: <a href="https://docs.google.com/document/d/1ZfBU5fBKXLzLg02boq3JZNJo0FjhNsKR/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de manejo de rupturas de medicamentos',
            code: '',
            link: <a href="https://docs.google.com/document/d/1DpZa9IpyoOdWXu0MmDAXUcDOfdQQjDuz/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de manejo de derrames de medicamentos',
            code: '',
            link: <a href="https://docs.google.com/document/d/1wfvNLdaiGjQo96SJcloDfN2CaH5oJRMx/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de manejo de trauma',
            code: '',
            link: <a href="https://docs.google.com/document/d/11gSs9uTe5ecyyirwdlUKKPGGUbQ6SXhe/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de manejo de medicamentos lasa',
            code: '',
            link: <a href="https://docs.google.com/document/d/11gSs9uTe5ecyyirwdlUKKPGGUbQ6SXhe/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo kit de victimas de violencia sexual',
            code: '',
            link: <a href="https://docs.google.com/document/d/11gSs9uTe5ecyyirwdlUKKPGGUbQ6SXhe/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de administración segura de medicamentos',
            code: '',
            link: <a href="https://docs.google.com/document/d/14lJl3SDSwUZbIYK1E0QhBKWeMK_MCUic/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de prevención de caidas',
            code: '',
            link: <a href="https://docs.google.com/document/d/1E4-9459b44FES3soW3mBZ9XwwUIMXJWB/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de ronda de seguridad',
            code: '',
            link: <a href="https://docs.google.com/document/d/1E4-9459b44FES3soW3mBZ9XwwUIMXJWB/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de no rehuso de dispositivos médicos',
            code: '',
            link: <a href="https://docs.google.com/document/d/1lho54YO5aDwtarru7twQLG9lFgLFBh-0/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de bioseguridad',
            code: '',
            link: <a href="https://docs.google.com/document/d/1Wkjr6pnTQk0iGHBZp1rWUviTzjG9r_Yp/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de referencia y contra referencia',
            code: '',
            link: <a href="https://docs.google.com/document/d/1LRstPpK7US2sm7q2QJkVOG8jNnJrdHBa/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de referencia y contra referencia',
            code: '',
            link: <a href="https://docs.google.com/document/d/1LRstPpK7US2sm7q2QJkVOG8jNnJrdHBa/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Programa de limpieza y desinfección',
            code: '',
            link: <a href="https://docs.google.com/document/d/1LRstPpK7US2sm7q2QJkVOG8jNnJrdHBa/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Procedimiento',
            name: 'Procedimiento de lavado de tanques',
            code: '',
            link: <a href="https://docs.google.com/document/d/1La5U4AV7s4OzwEsY3vyDUv4UMg9gH1Gn/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de autocuidado en salud',
            code: '',
            link: <a href="https://docs.google.com/document/d/1La5U4AV7s4OzwEsY3vyDUv4UMg9gH1Gn/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
        {
            id: '1',
            type: 'Protocolo',
            name: 'Protocolo de gestión y desarrollo de una adecuada comunicación entre paciente y cuidador... ',
            code: '',
            link: <a href="https://docs.google.com/document/d/1KuVhZhSOT7ln99EHaFeb6Ddacuhi3_iy/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },
    ]

    const [search, setSearch] = useState('');

    const filterData = data.filter((data)=> {
       return data.name.toLowerCase().includes(search.toLowerCase())
   })

    const handleSearch = (e) => {
       setSearch(e.target.value)
    }
     
    return (
        <>
        <Sidebar/>
        <div className='documentos'>
            <h2>Documentos<span>.</span></h2>
            <Docbar /> 
            <div className="documentos__container">
                <h3>Protocolos y procedimientos</h3>
                <div className="documentos__search">
                    <Input
                        type='text'
                        placeholder={'Buscar formato'}
                        value={search}
                        onChange={handleSearch}
                    />
                </div>
                <table>
                        <tr>
                            <th><b>Tipo de Documento</b></th>
                            <th><b>Nombre del documento</b></th>
                            <th><b>Código</b></th>
                            <th><b>Última versión</b></th>
                            <th><b>Acciones</b></th>
                           
                        </tr>
                        {
                                    filterData.map( item => 
                                        <tr>
                                            <td>{item.type}</td>
                                            <td>{item.name}</td>
                                            <td>{item.code}</td>
                                            <td className='tb__container'>{item.link}</td>
                                        </tr>
                                        )
                                }
                    </table>
            </div>
        </div>
        </>
    )
}

export default Protocolos
