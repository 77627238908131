import React from 'react';
import './Evento.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

function Evento() {
    const data = [
        {
            key: '1',
            label: 'Nombre del evento',
            type: 'text'
        },
        {
            key: '2',
            label: 'Fecha de inicio',
            type: 'date'
        },
        {
            key: '3',
            label: 'hora inicio',
            type: 'time'
        },
        {
            key: '4',
            label: 'Fecha fin',
            type: 'date'
        },
        {
            key: '5',
            label: 'Hora fin',
            type: 'time'
        },
        {
            key: '6',
            label: 'Asistentes',
            type: 'email',
        },
    ]
  return (
    <>
    <Sidebar/>
    <div className="evento">
        <h2>Agregar un evento.</h2>
        <div className="evento__container">
            <form action="">
           {
            data.map(item => 
                <Input
                    key={item.key}
                    label={item.label} 
                    type={item.type}
                    text={item.text}
                />  
            )
           }
           <div className="evento__btn">
                <Button
                    title='Agregar evento'
                />
           </div>
              

            </form>
        </div>

    </div>
    </>
  )
}

export default Evento